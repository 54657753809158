export const songs = [
  {
    artist: "Dire Straits",
    name: "Brothers In Arms",
    youtubeId: "jhdFe3evXpk",
  },
  {
    artist: "David Guetta",
    name: "Titanium",
    youtubeId: "JRfuAukYTKg",
  },
  {
    artist: "Bastille",
    name: "Pompeii",
    youtubeId: "F90Cw4l-8NY",
  },
  {
    artist: "Tom Odell",
    name: "Another Love",
    youtubeId: "MwpMEbgC7DA",
  },
  {
    artist: "Marshmello & Anne-Marie",
    name: "FRIENDS",
    youtubeId: "CY8E6N5Nzec",
  },
  {
    artist: "Coldplay",
    name: "Paradise",
    youtubeId: "1G4isv_Fylg",
  },
  {
    artist: "Panic! At The Disco",
    name: "High Hopes",
    youtubeId: "IPXIgEAGe4U",
  },
  {
    artist: "Bastille",
    name: "Million Pieces",
    youtubeId: "6OLp72eLLc4",
  },
  {
    artist: "Imagine Dragons",
    name: "Radioactive",
    youtubeId: "ktvTqknDobU",
  },
  {
    artist: "John Newman",
    name: "Love Me Again",
    youtubeId: "CfihYWRWRTQ",
  },
  {
    artist: "Wiz Khalifa ft. Charlie Puth",
    name: "See You Again",
    youtubeId: "RgKAFK5djSk",
  },
  {
    artist: "Coldplay",
    name: "Adventure Of A Lifetime",
    youtubeId: "QtXby3twMmI",
  },
  {
    artist: "OneRepublic",
    name: "Counting Stars",
    youtubeId: "hT_nvWreIhg",
  },
  {
    artist: "Harry Styles",
    name: "As It Was",
    youtubeId: "H5v3kku4y6Q",
  },
  {
    artist: "Shawn Mendes",
    name: "Stitches",
    youtubeId: "EByFZdw4wEA",
  },
  {
    artist: "Lukas Graham",
    name: "7 Years",
    youtubeId: "kqFLQG484pg",
  },
  {
    artist: "Rag'n'Bone Man",
    name: "Human",
    youtubeId: "L3wKzyIN1yk",
  },
  {
    artist: "Twenty one pilots",
    name: "Heathens",
    youtubeId: "zq2pagG8_ok",
  },
  {
    artist: "Imagine Dragons",
    name: "Demons",
    youtubeId: "mWRsgZuwf_8",
  },
  {
    artist: "Bastille",
    name: "Glory",
    youtubeId: "zxJCMOMx550",
  },
  {
    artist: "Avicii",
    name: "Waiting For Love",
    youtubeId: "cHHLHGNpCSA",
  },
  {
    artist: "Hozier",
    name: "Take Me To Church",
    youtubeId: "MYSVMgRr6pw",
  },
  {
    artist: "Coldplay",
    name: "A Sky Full Of Stars",
    youtubeId: "VPRjCeoBqrI",
  },
  {
    artist: "Imagine Dragons",
    name: "Thunder",
    youtubeId: "GtEvysh1654",
  },
  {
    artist: "The Neighbourhood",
    name: "Sweater Weather",
    youtubeId: "GCdwKhTtNNw",
  },
  {
    artist: "Capital Cities",
    name: "Safe And Sound",
    youtubeId: "47dtFZ8CFo8",
  },
  {
    artist: "Nico & Vinz",
    name: "Am I Wrong",
    youtubeId: "bg1sT4ILG0w",
  },
  {
    artist: "Mark Ronson",
    name: "Uptown Funk",
    youtubeId: "OPf0YbXqDm0",
  },
  {
    artist: "The Script",
    name: "Hall of Fame",
    youtubeId: "3Kxf2dHlDpQ",
  },
  {
    artist: "RAYE",
    name: "Escapism",
    youtubeId: "habEOGWPas0",
  },
  {
    artist: "Echosmith",
    name: "Cool Kids",
    youtubeId: "fomDBGr4LpE",
  },
  {
    artist: "Becky Hill, David Guetta",
    name: "Remember",
    youtubeId: "NhCxrUEW3PM",
  },
  {
    artist: "AC/DC",
    name: "Thunderstruck",
    youtubeId: "v2AC41dglnM",
  },
  {
    artist: "AC/DC",
    name: "Highway to Hell",
    youtubeId: "l482T0yNkeo",
  },
  {
    artist: "AC/DC",
    name: "Back In Black",
    youtubeId: "pAgnJDJN4VA",
  },
  {
    artist: "Rick Astley",
    name: "Never Gonna Give You Up",
    youtubeId: "dQw4w9WgXcQ",
  },
  {
    artist: "Rise Against",
    name: "Prayer Of The Refugee",
    youtubeId: "9-SQGOYOjxs",
  },
  {
    artist: "50 Cent",
    name: "In Da Club",
    youtubeId: "H3axP4norfk",
  },
  {
    artist: "Madonna",
    name: "American Life",
    youtubeId: "H3axP4norfk",
  },
  {
    artist: "Avril Lavigne",
    name: "I'm With You",
    youtubeId: "DhawV3ppm9c",
  },
  {
    artist: "Coldplay",
    name: "Clocks",
    youtubeId: "d020hcWA_Wg",
  },
  {
    artist: "Eminem",
    name: "Sing For The Moment",
    youtubeId: "NtPY1_p9KKg",
  },
  {
    artist: "P!nk",
    name: "Just Give Me A Reason",
    youtubeId: "OpQFFLBMEPI",
  },
  {
    artist: "Rihanna",
    name: "Stay",
    youtubeId: "JF8BRvqGCNs",
  },
  {
    artist: "David Bowie",
    name: "Let's Dance",
    youtubeId: "VbD_kBJc_gI",
  },
  {
    artist: "Maroon 5",
    name: "Payphone",
    youtubeId: "fuP4Lkt1vAo",
  },
  {
    artist: "Bruno Mars",
    name: "The Lazy Song",
    youtubeId: "fLexgOxsZu0",
  },
  {
    artist: "Drake",
    name: "Search & Rescue",
    youtubeId: "ntp9_iznQ-0",
  }
];
