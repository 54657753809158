import { songs } from "../constants";

const msInDay = 86400000;
const startDate = new Date('4/10/2023');
const todaysDate = new Date();
const index = Math.floor((todaysDate.getTime() - startDate.getTime() )/msInDay)
//const generator =  seedrandom(`${songs[Math.floor(index % songs.length)].name}`);
//const randomNumber = generator();

//export const todaysSolution = songs[Math.floor(random(index + 1) * songs.length)];
export const todaysSolution = songs[0];

//export const todaysSolution = songs[Math.floor(Math.random() * songs.length)];
function random(seed: number) {
    let n = seed
    const x = Math.sin(n++) * 10000;
    return x - Math.floor(x);
}